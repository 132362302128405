<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <div class="container">
      <p>{{ intro }}</p>
      <img width="80%" src="../assets/spiral.png" />

      <image-uploader
        class="uploader"
        :debug="1"
        :maxWidth="1024"
        :maxHeight="1024"
        :quality="0.7"
        :autoRotate="false"
        outputFormat="string"
        :preview="false"
        :className="['fileinput', { 'fileinput--loaded': hasImage }]"
        :capture="false"
        accept="image/*"
        doNotResize="['gif', 'svg']"
        @input="setImage"
        @onUpload="startImageResize"
        @onComplete="endImageResize"
      >
        <label for="fileInput" slot="upload-label">
          <figure>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
            >
              <path
                class="path1"
                d="M9.5 19c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5-2.91-6.5-6.5-6.5-6.5 2.91-6.5 6.5zM30 8h-7c-0.5-2-1-4-3-4h-8c-2 0-2.5 2-3 4h-7c-1.1 0-2 0.9-2 2v18c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-18c0-1.1-0.9-2-2-2zM16 27.875c-4.902 0-8.875-3.973-8.875-8.875s3.973-8.875 8.875-8.875c4.902 0 8.875 3.973 8.875 8.875s-3.973 8.875-8.875 8.875zM30 14h-4v-2h4v2z"
              ></path>
            </svg>
          </figure>
          <span class="upload-caption">{{
            hasImage ? "Replace your drawing" : "Click to upload your drawing"
          }}</span>
        </label>
      </image-uploader>
      <div v-if="hasImage">
        <h1>Here is the drawing you've uploaded:</h1>
        <img :src="image" width="80%" />
      </div>
      <div v-if="hasResult">
        <h1>Your TETRAS spiral drawing score is {{ score }}</h1>
        <p>
          The Essential Tremor Rating Assessment Scale (TETRAS) developed by the
          Tremor Research Group (TRG) provides a comprehensive quantification of
          essential tremor. Spiral drawing is one of the nine performance tasks
          of TETRAS. According to TETRAS, the spiral drawing scores are defined
          by 0-4 whole numbers:
        </p>
        <ul>
          <li>0 = normal,</li>
          <li>1 = slight: tremor barely visible,</li>
          <li>2 = mild: obvious tremor,</li>
          <li>3 = moderate: portions of figure not recognizable,</li>
          <li>4 = severe: figure not recognizable.</li>
        </ul>
        <div class="disclaimer">
          <p>
            <strong>Disclaimer:</strong> this online tool is only intended for
            measuring the presence and relative severity of hand tremor in the
            spiral drawing task. It is not intended for the diagnosis or
            treatment of any disease or condition. Nor is it intended for
            tracking the progression of any disease or condition. Please discuss
            with your physician if you have any questions or concerns about your
            TETRAS spiral drawing score above.
          </p>
        </div>
      </div>
      <div v-if="hasError">
        <h1>We are sorry, but there is an error in the uploaded image.</h1>
        <p>
          We cannot analyze the spiral drawing in this image. Please check if it is a valid image file and try again.
        </p>
      </div>    
    </div>
    <div class="footer">Copyright 2022 Fasikl Inc. All rights reserved.</div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  data() {
    return {
      msg: "Welcome To Spiral Drawing Test!",
      intro:
        "Tremor is the most common movement disorder. The assessment of tremor is in general carried out in a very subjective way. The Archimedean spiral drawing is a standardized test designed to capture the frequency, amplitude and direction of a tremor for a quantitative assessment. To perform this test, grab a pen (for the best result, use a 0.9-1.2 mm thick ballpoint pen) and a piece of paper (approximately 1/4 of an unlined page of standard A4/letter paper), and draw the following Archimedean spiral. The lines of the spiral should be approximately 1.3 cm (0.5 inch) apart. The pen should be held such that no part of your limb touches the table. After that, take a photo of your drawing and upload it. Our AI algorithm will measure your tremor in the drawing.",
      hasImage: false,
      hasResult: false,
      hasError: false,
      image: null,
      score: 0,
    };
  },
  methods: {
    setImage: function (output) {
      this.image = output;
      this.hasImage = true;
      this.axios.post('https://fasikl.ai:8080/upload', {'image': this.image})
      .then(response => {
        this.score = response.data.score;
        this.hasResult = true;
        this.hasError = false;
        })
      .catch(error => {
        console.log(error);
        this.hasResult = false;
        this.hasError = true;
      })
    },
    startImageResize: function () {
      // console.log("Start image upload:", this.image);
      // console.log("Exif", output.exif);
    },
    endImageResize: function () {
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.container {
  margin: auto;
  width: 768px;
  padding: 10px;
}

@media only screen and (max-width: 960px) {
  /* For mobile phones: */
  [class="container"] {
    width: 80%;
  }
}
#fileInput {
  display: none;
}
h1,
h2 {
  font-weight: 700;
  margin: 20px 0;
}
ul {
  list-style-type: none;
  padding: 0;
  text-align: left;
}
li {
  /* display: inline-block; */
  margin: 0 40px;
}
p {
  text-align: left;
}
a {
  color: #42b983;
}
.disclaimer {
  margin: 20px 0;
  padding: 16px;
  color: rgb(148, 124, 45);
  border: 1px solid rgba(250, 202, 43, 0.2);
  background-color: rgba(250, 202, 43, 0.2);
}

.uploader {
  padding: 16px;
  width: 80%;
  margin: auto;
  border: 1px solid rgba(124, 124, 124, 0.2);
  background-color: rgba(124, 124, 124, 0.2);
}
.footer {
  display: block;
  color: rgba(49, 51, 63, 0.4);
  flex: 0 1 0%;
  font-size: 12px;
  width: 100%;
  margin: 60px 0 0 0;
}
</style>
